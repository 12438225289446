import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { ContentInPolishLanguageAlert } from "../components/ContentInPolishLanguageAlert";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const CookiesPolicy: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout
      seoTitle={t("cookiesPolicyLink")}
      seoDescription={t("cookiesPolicyLink")}
      location={location}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <section className="page-header">
              <ContentInPolishLanguageAlert what={t("cookiesPolicyLink")} />

              <h2>Niniejsza strona korzysta z plików cookie</h2>
              <p>
                Inspecto Sp. z o.o. wykorzystuje pliki cookies do
                spersonalizowania treści i reklam, aby świadczyć Usługi, a także
                oferować funkcje społecznościowe i analizować ruch w Serwisie.
                Informacje o tym, w jaki spos&oacute;b korzystasz z Serwisu,
                mogą być udostępniane partnerom Inspecto Sp. z o.o.: reklamowym,
                społecznościowym czy analitycznym. Partnerzy mogą połączyć te
                informacje z innymi danymi otrzymanymi od Ciebie lub uzyskanymi
                podczas korzystania z ich usług.
              </p>

              <p>
                &gt; Zezw&oacute;l na wszystkie ciasteczka
                <br />
                &gt; Zezw&oacute;l na wyb&oacute;r
                <br />
                &gt; Korzystaj wyłącznie z niezbędnych plik&oacute;w cookie
              </p>

              <p>
                <strong>Opcje do wyboru:</strong>
                <br />
                &bull; niezbędne
                <br />
                &bull; preferencje
                <br />
                &bull; statystyka
                <br />
                &bull; marketingowe
              </p>

              <p>
                <strong>Szczeg&oacute;ły:</strong>
                <br />
                Deklaracja dot. plik&oacute;w cookie
                <br />
                1. Niezbędne pliki cookie przyczyniają się do użyteczności
                strony poprzez umożliwianie podstawowych funkcji takich jak
                nawigacja na stronie i dostęp do bezpiecznych obszar&oacute;w
                strony internetowej. Strona internetowa nie może funkcjonować
                poprawnie bez tych ciasteczek.
                <br />
                2. Pliki cookie dotyczące preferencji umożliwiają stronie
                zapamiętanie informacji, kt&oacute;re zmieniają wygląd lub
                funkcjonowanie strony, np. preferowany język lub region, w
                kt&oacute;rym znajduje się użytkownik.
                <br />
                3. Statystyczne pliki cookie pomagają właścicielem stron
                internetowych zrozumieć, w jaki spos&oacute;b r&oacute;żni
                użytkownicy zachowują się na stronie, gromadząc i zgłaszając
                anonimowe informacje.
                <br />
                4. Marketingowe pliki cookie stosowane są w celu śledzenia
                użytkownik&oacute;w na stronach internetowych. Celem jest
                wyświetlanie reklam, kt&oacute;re są istotne i interesujące dla
                poszczeg&oacute;lnych użytkownik&oacute;w i tym samym bardziej
                cenne dla wydawc&oacute;w i reklamodawc&oacute;w strony
                trzeciej.
                <br />
                5. Nieklasyfikowane pliki cookie, to pliki, kt&oacute;re są w
                procesie klasyfikowania, wraz z dostawcami poszczeg&oacute;lnych
                ciasteczek.
              </p>

              <p>
                Szczeg&oacute;łowe informacje o plikach cookie znajdują się w
                Polityce Prywatności Inspecto Sp. z o.o.
              </p>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CookiesPolicy;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
